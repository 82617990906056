import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/shutterstock_526305043.jpg'
import styles from './post-grid.module.css'

const SupplementWithFishOil = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Proč byste měli suplementovat rybí olej?'}
        description={
          'Ryby jsou považovány za jednu z nejzdravějších potravin a rybí olej patří po celém světě mezi nejznámější suplementy. Většina obyvatel ČR má však s příjmem ryb problém a v jídelníčku jim chybí především ty tučné, jako je například losos, makrela, mořský úhoř a sleď. Ideálně by měl člověk konzumovat tučnou rybu 2x týdně, což většina populace Česka nesplňuje. Jakožto vnitrozemský stát nemáme tak snadný přístup k čerstvým tučným rybám, což je jeden z důvodů, proč je konzumujeme málo. Spoustu lidí také nejí ryby vůbec. Z tohoto důvodu je vhodné alespoň doplňovat rybí olej. Hlavním důvodem suplementace rybího oleje je, že obsahuje pro tělo potřebné omega 3 mastné kyseliny, ale také vitamíny A, D a E. '
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/why-should-you-supplement-with-fish-oil">
            Proč byste měli suplementovat rybí olej?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Proč byste měli suplementovat rybí olej?"
              date="05.06.2023"
            />
            <div>
              <p className={styles.postText}>
                Ryby jsou považovány za jednu z nejzdravějších potravin a <b>rybí olej</b> patří po celém světě mezi <b>nejznámější suplementy</b>. Většina obyvatel ČR má však s příjmem ryb problém a {' '}
                <b>v jídelníčku jim chybí</b> především ty <b>tučné</b>, jako je například losos, makrela, mořský úhoř a sleď. Ideálně by měl člověk konzumovat tučnou <b>rybu 2x týdně</b>, což většina populace Česka nesplňuje. Jakožto vnitrozemský stát nemáme tak snadný přístup k čerstvým tučným rybám, což je jeden z důvodů, proč je konzumujeme málo. Spoustu lidí také nejí ryby vůbec. Z tohoto důvodu je vhodné alespoň
                <b> doplňovat rybí olej</b>. Hlavním důvodem suplementace rybího oleje je, že obsahuje pro tělo potřebné <b>omega 3 mastné kyseliny</b>, ale také <b>vitamíny A, D a E</b>.
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v krabicích"
              />

              <p className={styles.postText}>Co jsou omega 3 mastné kyseliny a k čemu jsou dobré?</p>

              <p className={styles.postText}>Jedná se o tzv. “zdravé tuky”, které jsou pro tělo <b>nepostradatelné </b> a jelikož si je neumí samo vyrobit, je nutné je
                <b> pravidelně dodávat ve stravě</b> či v doplňcích stravy. Jedná se tedy o <b>esenciální </b> mastné kyseliny. Vyskytují se <b>především v rybím oleji</b>, ale některé kyseliny najdeme například ve vejcích, chia a lněných semínkách, lněném, řepkovém a sójovém oleji, vlašských ořeších nebo fazolích.
              </p>

              <p className={styles.postText}>Omega 3 mastné kyseliny mají pozitivní účinky na řadu problémů. Působí <b>protizánětlivě</b>, pomáhají <b>regulovat krevní tlak a hladinu tuku v krvi</b>, snižují riziko srdečních chorob díky <b>podpoře správné činnosti srdce</b>, a také mají pozitivní vliv na
                <b> činnost mozku a zraku</b>. Jejich příjem je velmi důležitý u těhotných, protože přispívá k <b>normálnímu vývoji mozku a zraku</b> u plodu v těle matky a následně i u kojenců.
              </p>

              <p className={styles.postText}>Co se děje s tělem, když nemá dostatek omega 3 mastných kyselin?</p>

              <p className={styles.postText}>Existuje mnoho příznaků, díky kterým poznáte, že nemáte v těle dostatečné množství omega 3 mastných kyselin. Jedná se zejména o: bolesti kloubů, <b>únavu</b>, změny nálad či deprese, lupy, <b>suchou pokožku</b>, vysoký krevní tlak, <b>zhoršení paměti, zhoršení zraku</b> a mnoho dalších.</p>

              <p className={styles.postText}>Jaká je doporučená denní dávka omega 3 mastných kyselin?</p>

              <p className={styles.postText}>Doporučená denní dávka se obecně uvádí <b>pro muže 1,6 g a pro ženy 1,1 g</b>, pro <b>těhotné ženy 1,4 g a kojící ženy 1,3 g.</b> Pro <b>děti </b> je to od cca <b>0,5 – 1,6 g</b> v závislosti na věkové kategorii. Na doplňcích obsahujících omega 3 mastné kyseliny je vždy uvedeno množství konkrétní kyseliny i <b>doporučená denní dávka.</b> Nemusíte tedy nic vypočítávat.</p>

              <p className={styles.postText}>Jak suplementovat omega 3 mastné kyseliny?</p>

              <p className={styles.postText}>Možná si myslíte, že rybí tuk je nutné doplňovat v <b>čisté formě</b> tzv. “na lžičku a do úst”. <b>Dnes už ale existuje mnoho forem</b>, jak olej doplňovat, aniž by se vám z něj musel zvedat žaludek. Existuje například <b>mnoho příchutí</b>, jako je jablečná či citronová, která chuť oleje překrývá. Ideálním řešením jsou ovšem <b>kapsle</b>, díky kterým vůbec chuť oleje necítíte.</p>

              <p className={styles.postText}>Další výhody rybího oleje</p>

              <p className={styles.postText}>Vitamin D, který olej obsahuje, <b>podporuje zdraví kostí a zubů</b>. Vitamin A je důležitý pro <b>zdravou pokožku a zrak</b> a společně s vitaminem D <b>pozitivně působí na imunitu</b>. Vitamin E chrání buňky před oxidativním stresem (patří mezi <b>antioxidanty</b>).</p>

              <p className={styles.postText}>A co je závěrem?</p>

              <p className={styles.postText}>Rybí tuk je pro nás i naše tělo důležitou složkou, a proto <b>by měl být zahrnut i v našem jídelníčku</b>. Konzumace tučných ryb je u osob žijících v Česku jednoznačně <b>nedostatečná</b>, a v takovém případě určitě stojí za zvážení <b>suplementace rybího tuku</b> na denní bázi. </p>

            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default SupplementWithFishOil
